import Vue from 'vue'

export default {
    csrfHeader() {
        const cookie = document.cookie.match(new RegExp(`XSRF-TOKEN=([^;]+)`));
        if (cookie === null || cookie.length < 2) {
            return {
                'X-XSRF-TOKEN': "",
            }
        } else {
            return {
                'X-XSRF-TOKEN': cookie[1],
            }
        }
    },
    headers() {
        return {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            ...this.csrfHeader()
        }
    },
    acceptOffer(offerId) {
        const config = {
            headers: this.headers()
        };

        try {
            const data = "{ \"id\" : \"" + offerId + "\", \"decision\" : \"ACPT\" }";
            console.log("Sending PUT request to /applicant/offer - payload = " + data);
            return Vue.prototype.$http.put('/applicant/offer', data, config);
        } catch (error) {
            console.log("Caught an error in the try block of acceptOffer()");
            console.log(error);
            return null;
        }
    },
    deleteDocument(documentGuid) {
        const config = {
            headers: this.csrfHeader()
        };
        return Vue.prototype.$http.delete(`/document/${documentGuid}`, config);
    },
    finaliseDocument(documentGuid) {
        const config = {
            headers: this.csrfHeader()
        };
        return Vue.prototype.$http.put(`/document/${documentGuid}`, null, config);
    },
    getReferenceData() {
        return Vue.prototype.$http.get('/reference-data');
    },
    getApplicantSummary() {
        return Vue.prototype.$http.get('/applicant-summary');
    },
    getDepositPaymentResponseDelayLists() {
        return Vue.prototype.$http.get('/applicant-payment-response-delay');
    },
    submitReadmissionApplication() {
        return Vue.prototype.$http.put(`/readmission/submitReadmissionApplication`);
    },
    saveAdditionalInformation(formData) {
        return Vue.prototype.$http.put(`readmission/additional-info`, formData);
    },
    getDocumentSummaries() {
        return Vue.prototype.$http.get('/document/summaries');
    },
    getIntentions() {
        return Vue.prototype.$http.get('/applicant/intentions');
    },
    getExternalLink(type) {
        return Vue.prototype.$http.get(`/external-link/${type}`);
    },
    getPerson() {
        return Vue.prototype.$http.get('/applicant/person');
    },
    setTermsOfUseAccepted() {
        const config = {
            headers: this.csrfHeader()
        };
        return Vue.prototype.$http.put('/content/terms', null, config);
    },
    getTermsOfUseMessage() {
        return Vue.prototype.$http.get('/content/terms');
    },
    updatePerson(person) {
        return Vue.prototype.$http.put('/applicant/person/person', person);
    },
    payFees() {        
        const config = { headers: this.headers()};        
        return Vue.prototype.$http.post(`/payment/fee-amount`, null, config);
    },
    acceptOrDeclineOffers(offerStatuses) {        
        const config = { headers: this.headers()};        
        return Vue.prototype.$http.post(`/payment/offer-statuses/`, offerStatuses, config);
    },
    supplementalFee(payload) {
        return Vue.prototype.$http.post(`/payment/supplemental-fee/`, payload);
    },
    personalProfileFee(payload) {
        return Vue.prototype.$http.post(`/payment/personalProfile-fee/`, payload);
    },
    reAdmissionPayment(payload) {
        return Vue.prototype.$http.post(`/payment/reAdmissionPayment/`, payload);
    },
    updateOfferStatuses(offerStatuses) {
        return Vue.prototype.$http.put(`/applicant/offer/`, offerStatuses);
    },
    updateOuacNumber(ouacNumber) {
        const config = {
            headers: this.csrfHeader()
        };
        return Vue.prototype.$http.put(`/applicant/ouac/${ouacNumber}`, null, config);
    },
    updatePenNumber(penNumber) {
        const config = {
            headers: this.csrfHeader()
        };
        return Vue.prototype.$http.put(`/applicant/pen/${penNumber}`, null, config);
    },
    uploadDocument(documentGuid, formData) {
        return Vue.prototype.$http.post(`/document/${documentGuid}`,
            formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    ...this.csrfHeader()
                }
            }
        );
    },
    UpdateAboriginalSelfIdentification(personBio) {
        const config = {
            headers: this.headers()
        };

        try {
            const data = JSON.stringify(personBio);
            return Vue.prototype.$http.put('/applicant/person/aboriginalselfidentification', data, config);
        } catch (error) {
            console.log("Caught an error in the try block of UpdateAboriginalSelfIdentification()");
            console.log(error);
            return null;
        }

    },
    getTransferCredits() {
        return Vue.prototype.$http.get('/transfer-credit');
    },
    getPersonalProfile(personalProfileCriteria) {
        return Vue.prototype.$http.get('/personalProfile', {params: personalProfileCriteria});
    },
    savePersonalProfile(personalProfileForm) {
        return Vue.prototype.$http.post('/personalProfile', personalProfileForm);
    },
    submitPersonalProfile(personalProfileForm) {
        return Vue.prototype.$http.post('/personalProfile', personalProfileForm);
    },
    getFormText() {
        return Vue.prototype.$http.get('/readmission/formtext');
    },
    programSelection() {
        return Vue.prototype.$http.get('/readmission/programSelection');
    },
    getCurrentApplicationForMainPage() {
        return Vue.prototype.$http.get(`/readmission/application-for-main-page`);
    },
    getCurrentApplication() {
        return Vue.prototype.$http.get(`/readmission/application`);
    },
    keepServerAlive() {
        return Vue.prototype.$http.get('/authenticate/status');
    },
    saveReadmissionProgram(formData) {
        return Vue.prototype.$http.post('/readmission/intentions', formData);
    },
    savePreviousInstitutions(formData) {
        return Vue.prototype.$http.post('/readmission/previousInstitutions', formData);
    },
    savePreviousUbcRegistration(formData) {
        return Vue.prototype.$http.post('/readmission/savePreviousUbcRegistration', formData);
    },
    loadPreviousInstitutions() {
        return Vue.prototype.$http.get('/readmission/loadPreviousInstitutions');
    },
    getReadmissionDocuments() {
        return Vue.prototype.$http.get(`/readmission/documents`);
    },
    getReadmissionMiscDocumentRules() {
        return Vue.prototype.$http.get(`/readmission/misc-document-rules`);
    },
    getReadmissionFee() {
        return Vue.prototype.$http.get('/readmission/readmissionFee');
    },
    loadPreviousUbcRegistration() {
        return Vue.prototype.$http.get('/readmission/loadPreviousUbcRegistration');
    },
    saveBiographicalDetails(formData) {
        return Vue.prototype.$http.post('/readmission/saveBiographicalDetails', formData);
    },
}


