import Vue from 'vue'
import VueRouter from 'vue-router'
import App from './App'
import Application from '@/components/Application'
import Documents from '@/components/Documents'

Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'history',
    routes: [
        { path: process.env.BASE_URL, component: App },
        { path: '/application', component: Application },
        { path: '/documents', component: Documents },
        { path: '*', redirect: process.env.BASE_URL }
    ]
});

export default router;