import api from "../../api/backend-api";
import Vue from 'vue';
import PersonalProfileCriteria from "@/api/PersonalProfileCriteria";

export const namespaced = true;

const state = {
    formText: null,
    programSelection: {
        degreePrograms: [],
        sessions: [],
        degreeSpecializations: [],
        programVersions: [],
    },
    campus: "",
    degreeChoiceCode: "",
    degreeOptionChoiceCode: "",
    degreeOptionChoiceCode2: "",
    session: "",
    programVersion: "",
    preferredName: "",
    hadFailedYear: false,
    comment: "",
    additionalInformation: [],
    choices: [{
        campus: "",
        degreeChoiceCode: "",
        degreeOptionChoiceCode: "",
        degreeOptionChoiceCode2: "",
        programVersion: "",
        session: "",
    }],
    isBusy: false,
    savingError: "",
    previousInstitutions: [],
    currentApplication: null,
    readmissionDocuments: [],
    additionalInfoLoaded: false,
    initPreviousInstitutionsDone: false,
    readmissionMiscDocumentRules: null,
    readmissionFee: null,
    readmissionFeePaymentDelay: false,
    previousUbcRegistration: null,
};

const getters = {
    formText(state) {
        return state.formText;
    },
    additionalInformation(state) {
        return state.additionalInformation;
    },
    programSelection(state) {
        return state.programSelection;
    },
    choices(state) {
        return state.choices;
    },
    previousInstitutions(state) {
        return state.previousInstitutions;
    },
    preferredName(state) {
        return state.preferredName;
    },
    hadFailedYear(state) {
        return state.hadFailedYear;
    },
    comment(state) {
        return state.comment;
    },
    readmissionDocuments(state) {
        return state.readmissionDocuments;
    },
    savingError(state) {
        return state.savingError;
    },
    isBusy(state){
        return state.isBusy;
    },
    currentApplication(state){
        return state.currentApplication;
    },
    readmissionMiscDocumentRules(state)
    {
        return state.readmissionMiscDocumentRules;
    },
    readmissionPersonalProfileCriteria(state) {
        let intentions = [];
        for(const choice of state.choices) {
            let intention = {
                degreeId: choice.degreeChoiceCode ,
                processingArea: "ADMS",
                applicationType: "REND",
                applEnteredDate: state.currentApplication.applEnteredDate
            }
            intentions.push(intention);
        }
        return new PersonalProfileCriteria(intentions, state.readmissionDocuments, state.readmissionMiscDocumentRules);
    },
    readmissionFee(state){
        return state.readmissionFee;
    },
    readmissionFeePaymentDelay(state){
        return state.readmissionFeePaymentDelay;
    },
    previousUbcRegistration(){
        return state.previousUbcRegistration;
    },
};

const actions = {
    loadFormText({commit}) {
        if (state.formText) {
            return;
        }
        api.getFormText()
            .then(response => {
                commit('setFormText', response.data);
            })
    },
    loadCurrentApplicationForMainPage({commit}) {
        commit('setIsBusy', true);
        api.getCurrentApplicationForMainPage()
            .then(response => {
                commit('setCurrentApplication', response.data.application);
                commit('setReadmissionFeePaymentDelay', response.data.reAdmissionFeesDelay);
            })
            .finally(() => {
                commit('setIsBusy', false);
            });
    },
    loadCurrentApplication({commit}) {
        commit('setIsBusy', true);
        api.getCurrentApplication()
            .then(response => {
                commit('setCurrentApplication', response.data.application);
                commit('setReadmissionFeePaymentDelay', response.data.reAdmissionFeesDelay);
            })
            .finally(() => {
                commit('setIsBusy', false);
            });
    },
    loadProgramSelectionData({commit}) {
        if (state.programSelection.degreePrograms.length) return;
        commit('setIsBusy', true);
        api.programSelection()
            .then(response => {
                commit('setProgramSelection', response.data);
            })
            .finally(() => {
                commit('setIsBusy', false);
            });
    },
    saveReadmissionProgram({commit, dispatch}, postData) {
        return api.saveReadmissionProgram(postData)
            .then(response => {
                commit('setSavingError', "");
                dispatch('loadReadmissionDocuments');
            })
            .catch(error => {
                commit('setSavingError', error.response.data.details);
            });
    },
    loadReadmissionDocuments({commit}) {
        api.getReadmissionDocuments()
            .then(response => {
                let docs = response.data;
                if (docs) {
                    commit('setReadmissionDocuments', docs);
                    if (docs.some(d => d.miscDocument === 'COM3')) {
                        commit('setFailed', true);
                    }
                   if ((docs.find(d => d.miscDocument === 'INT2'))) {
                        commit('setComment', ((docs.find(d => d.miscDocument === 'INT2')).document.comment));
                    }
                }
                commit('setAdditionalInfoLoadedFlag', true);
            })
    },
    saveAdditionalInformation({}, postData) {
        api.saveAdditionalInformation(postData)
            .then(response => {});
    },
    submitReadmissionApplication({}) {
        api.submitReadmissionApplication()
            .then(response => {});
    },
    savePreviousInstitutions({commit}, postData) {
        return api.savePreviousInstitutions(postData)
            .then(response => {
                commit('setSavingError', "");
            })
            .catch(error => {
                commit('setSavingError', error.response.data.message + ": " + error.response.data.details);
            });
    },
    savePreviousUbcRegistration({commit}, postData) {        
        return api.savePreviousUbcRegistration(postData)
            .then(response => {
                commit('setSavingError', "");
            })
            .catch(error => {
                commit('setSavingError', error.response.data.message + ": " + error.response.data.details);
            });
    },
    loadPreviousInstitutions({commit}) {
        if (state.initPreviousInstitutionsDone) {
            return;
        }
        commit('setIsBusy', true);
        api.loadPreviousInstitutions()
            .then(response => {
                commit('initPreviousInstitutions', response.data);
                commit('setInitPreviousInstitutionsDone', true);
            })
            .finally(() => {
                commit('setIsBusy', false);
            });
    },
    loadReadmissionFee({commit}) {
        if (state.readmissionFee) {
            return;
        }
        commit('setIsBusy', true);
        api.getReadmissionFee()
            .then(response => {
                commit('setReadmissionFee', response.data);
            })
            .finally(() => {
                commit('setIsBusy', false);
            });
    },
    loadReadmissionMiscDocumentRules({commit}) {
        if (state.readmissionMiscDocumentRules) {
            return;
        }
        commit('setIsBusy', true);
        api.getReadmissionMiscDocumentRules()
            .then(response => {
                commit('setReadmissionMiscDocumentRules', response);
            })
            .finally(() => {
                commit('setIsBusy', false);
            });
    },
    loadPreviousUbcRegistration({commit}) {
        api.loadPreviousUbcRegistration()
            .then(response => {
                commit('setPreviousUbcRegistration', response.data);
            })
            .finally(() => {
            });
    },
    saveBiographicalDetails({commit}, postData) {
        if (state.isBusy){
            return;
        }
        commit('setIsBusy', true);
        return api.saveBiographicalDetails(postData)
            .then(response => {
                commit('setSavingError', "");
            })
            .catch(error => {
                commit('setSavingError', error.response.data.message + ": " + error.response.data.details);
            })
            .finally(() => {
                commit('setIsBusy', false);
            });
    },
};

const mutations = {
    setFormText(state, payload) {
        state.formText = payload;
    },
    setCurrentApplication(state, payload) {
        state.currentApplication = payload;
    },
    setReadmissionFeePaymentDelay(state, payload) {
        state.readmissionFeePaymentDelay = payload;
    },
    setProgramSelection(state, payload) {
        state.programSelection = payload;
        let intentions = payload.intentions;
        for (const intention of intentions) {
            let index = intention.choice - 1;
            let initVal = {
                campus: intention.campusLocation,
                degreeChoiceCode: intention.degreeProgram,
                degreeOptionChoiceCode: intention.specialization1 ? intention.specialization1.id : '',
                degreeOptionChoiceCode2: intention.specialization2 ? intention.specialization2.id : '',
                programVersion: intention.credentialVersion,
                session: intention.sessionYear + intention.sessionCode + intention.term,
            };
            Vue.set(state.choices, index, initVal)
        }

    },
    updateChoice(state, payload) {
        let field = payload.key;
        let item = state.choices[payload.id];
        item[field] = payload.val;
        if (state.isBusy){
            return;
        }
        if (field === 'campus') {
            item['degreeChoiceCode'] = '';
            item['degreeOptionChoiceCode'] = '';
            item['degreeOptionChoiceCode2'] = '';
            item['programVersion'] = '';
        } else if (field === 'degreeChoiceCode'){
            item['degreeOptionChoiceCode'] = '';
            item['degreeOptionChoiceCode2'] = '';
            item['programVersion'] = '';
        }
    },
    addNewChoice(state, payload){
        state.choices = [...state.choices, payload];
    },
    removeChoice(state, index){
        state.choices.splice(index, 1);
    },
    updatePreviousInstitution(state, payload) {
        let field = payload.key;
        let inst = state.previousInstitutions.find(i => i.id === payload.id);
        inst[field] = payload.val;
        if (state.isBusy){
            return;
        }
        if (field === 'country') {
            inst['province'] = '';
            inst['institution'] = '';
        }else if (field === 'province'){
            inst['institution'] = '';
        }
    },
    addNewPreviousInstitution(state, payload) {
        state.previousInstitutions = [...state.previousInstitutions, payload];
    },
    removePreviousInstitution(state, id) {
        let inst = state.previousInstitutions.find(i => i.id === id);
        let index = state.previousInstitutions.indexOf(inst);
        state.previousInstitutions.splice(index, 1);
    },
    initPreviousInstitutions(state, payload) {
        let index = 0;
        let previousInstitutions = payload.previousInstitutions;
        let documents = payload.documents;
        for (const inst of previousInstitutions) {
            const findIsOnLetterOfPermission = documents.find((e) =>
                e.institutionCode === inst.institutionCode && e.miscDocument === "LOPE"
            );
            const findIsRequiredToWithdraw = documents.find((e) =>
                e.institutionCode === inst.institutionCode && e.miscDocument === "RWTD"
            );
            let isOnLetterOfPermissionVal = (findIsOnLetterOfPermission) ? "Yes" : "No";
            let isRequiredToWithdrawVal = (findIsRequiredToWithdraw) ? "Yes" : "No";
            let val = {
                id: inst.id,
                country: inst.country,
                province: inst.province,
                institution: {
                    institutionCode: inst.institutionCode,
                    institutionDescription: inst.institutionDescription,
                },
                startDate: inst.startDate,
                endDate: inst.endDate,
                isOnLetterOfPermission: isOnLetterOfPermissionVal,
                isRequiredToWithdraw: isRequiredToWithdrawVal,
            };
            Vue.set(state.previousInstitutions, index, val);
            index++;
        }
    },
    setPreferredName(state, payload) {
        state.preferredName = payload;
    },
    hadFailedYear(state, payload) {
        state.hadFailedYear = payload;
    },
    setComment(state, comment) {
        state.comment = comment;
    },
    setAdditionalInfoLoadedFlag(state, additionalInfoLoadedFlag) {
        state.additionalInfoLoaded = additionalInfoLoadedFlag;
    },
    setIsBusy(state, value) {
        state.isBusy = value;
    },
    setSavingError(state, error) {
        state.savingError = error;
    },
    setReadmissionDocuments(state, payload) {
        state.readmissionDocuments = payload;
    },
    setInitPreviousInstitutionsDone(state, payload) {
        state.initPreviousInstitutionsDone = payload;
    },
    setReadmissionMiscDocumentRules(state, payload) {
        state.readmissionMiscDocumentRules = payload.data;
    },
    setReadmissionFee(state, payload) {
        state.readmissionFee = payload;
    },
    setPreviousUbcRegistration(state, payload) {
        state.previousUbcRegistration = payload;
    },
};

export default {
    namespaced : true,
    state,
    getters,
    actions,
    mutations
};