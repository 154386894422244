<template>
  <div id="app" style="overflow: auto; height: 100%">
    <IdleTimeOutModal/>
    <ErrorMessageWidget />
    <app-header/>
    <sub-header/>
    <main-page-container/>
    <page-footer/>
  </div>
</template>

<script>
import AppHeader from "./components/AppHeader.vue";
import MainPageContainer from "./components/MainPageContainer.vue";
import PageFooter from "./components/PageFooter.vue"
import SubHeader from "./components/SubHeader.vue";
import IdleTimeOutModal from "./components/IdleTimeOutModal.vue";
import ErrorMessageWidget from "./components/ErrorMessageWidget.vue";

export default {
  name: 'app',

  components: {
    AppHeader,
    MainPageContainer,
    PageFooter,
    SubHeader,
    IdleTimeOutModal,
    ErrorMessageWidget
  },
}
</script>
